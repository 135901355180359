import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import { moneyColumnStyles, Table } from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { bankRegistersQueryDataPropType } from './commonPropTypes.js';
import { getBankRegistersTableConfig } from './useAccountRegistersQuery.js';
import { convertQueryDataToTransactions } from './bankRegistersQuery.js';

const BankRegistersTableS = styled(Table)`
  thead {
    height: 45px;
  }

  tr {
    width: 100%;
    margin: 0;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 40px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 130px;
    display: ${({ hideCheckNumber }) => (hideCheckNumber ? 'none' : 'visible')};
  }

  ${moneyColumnStyles(5)};
  ${moneyColumnStyles(6)};
  ${moneyColumnStyles(7)};

  th:nth-child(8),
  td:nth-child(8) {
    width: 65px;
  }
`;

export function BankRegistersTable({
  data,
  accountId,
  searchTerm,
  isPrintPDF,
  hideCheckNumber,
  ...restTableOptions
}) {
  const transactions = convertQueryDataToTransactions(accountId)(data);

  const initialConfig = getBankRegistersTableConfig(
    hideCheckNumber,
    searchTerm,
  );

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    isPrintPDF ? R.init(initialConfig) : initialConfig,
    transactions,
  );

  return (
    <BankRegistersTableS
      rows={rows}
      headers={headers}
      columns={columns}
      isPrintPDF={isPrintPDF}
      sortQueries={sortQueries}
      hideCheckNumber={hideCheckNumber}
      {...restTableOptions}
    />
  );
}

BankRegistersTable.propTypes = {
  accountId: string,
  searchTerm: string,
  data: bankRegistersQueryDataPropType,
  isPrintPDF: bool,
  hideCheckNumber: bool,
};
