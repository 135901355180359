import React, { useContext } from 'react';
import styled from 'styled-components';
import { bool, object } from 'prop-types';
import { getThemeColor } from '@poly/admin-book';

import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { WhiteBodyWrapper } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { BankRegistersTable } from './BankRegistersTable.js';
import {
  bankRegistersQueryPropType,
  bankRegistersQueryDataPropType,
} from './commonPropTypes.js';
import { getAccountTransactionsByData } from './bankRegistersQuery.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';

const WhiteBodyWrapperS = styled(WhiteBodyWrapper)`
  background-color: ${getThemeColor(['lightest'])};

  thead {
    background-color: ${getThemeColor(['lightest'])};
  }
`;

export function BankRegistersBody({
  query,
  hideCheckNumber,
  data,
  tableProps,
}) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  return (
    <WhiteBodyWrapperS height={contentHeight}>
      {getAccountTransactionsByData(data).length === 0 ? (
        <EmptyListMessage />
      ) : (
        <BankRegistersTable
          accountId={query.accountId}
          data={data}
          searchTerm={query.searchTerm}
          {...tableProps}
          hideCheckNumber={hideCheckNumber}
          showScrollBar
        />
      )}
    </WhiteBodyWrapperS>
  );
}

BankRegistersBody.propTypes = {
  query: bankRegistersQueryPropType,
  hideCheckNumber: bool,
  data: bankRegistersQueryDataPropType,
  // we don't care about the one below
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object.isRequired,
};
