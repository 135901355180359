import {
  bool,
  func,
  shape,
  number,
  object,
  string,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  ProjectType,
  supplierEmailsTypes,
  supplierTypes,
  SupplierWOCTypes,
} from '@poly/constants';
import { commonFileValidators, validateFilesFunc } from '@poly/client-utils';
import {
  getMarkupPercentBase,
  convertDollarsToCents,
  propEqLegacy,
  pathEqLegacy,
} from '@poly/utils';
import { usePristineSubscribe } from '@poly/client-routing';
import {
  useOnSubmitSetStopSubmitting,
  UserSelect,
  MoneyInput,
  entities,
} from '@poly/admin-ui';
import {
  useFormSubscription,
  InputWarningMsg,
  FilePicker,
  FormField,
  Button,
  Text,
  Checkbox,
} from '@poly/admin-book';
import { LabelS } from '@poly/admin-book/src/InputLabel/index.js';

import { TextAreaS } from '../formsStyles.js';
import { SectionWrapper, InputWrapper, MainSectionWrapper } from './styles.js';
import { assignSupplierFormTypePropTypes } from './propTypes.js';
import { requestFreeQuoteNote } from '../sendWOCForm/constants.js';
import { CheckWOComponent } from './components/CheckWOCComponent.js';
import { NextTaskDueDateField } from '../fields/NextTaskDueDateField.js';
import { AssignSupplierFormWithSearch } from './components/AssignSupplierFormWithSearch/AssignSupplierFormWithSearch.js';
import {
  isSelectedSuppliersType,
  isAssignSupplierFormType,
  pickSelectedSuppliers,
  isSendWOCFormType,
  isGenerateWOCFormType,
} from './utils.js';
import { onKeyDownToPreventFormSubmit } from '../../../utils/form.js';
import { NotificationContacts } from '../../../sidebars/PurchaseOrderSidebar/form/purchaseOrderFormSections.js';
import { useClearPristineState } from '../../../sidebars/useClearPristineOnSidebarMount.js';

const assignSupplierFormId = 'assignSupplierFormId';

const SupplierNTEInputS = styled(MoneyInput)`
  input {
    width: 120px;
  }

  label {
    margin: 0 10px 0 0;
  }
`;

const NTEInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
`;

// getTmMarkupRules :: {client: Client property:Property} => TmMarkupRules
const getTmMarkupRules = R.either(
  R.path(['property', 'tmMarkupRules']),
  R.path(['client', 'tmMarkupRules']),
);

// getNTEAmountWithMarkup :: {nte: Number  project: Project} -> Number
const getNTEAmountWithMarkup = ({ nte, project }) =>
  R.compose(
    convertDollarsToCents,
    R.ifElse(
      R.equals(0),
      R.always(nte),
      R.compose(R.add(nte), R.multiply(nte)),
    ),
    R.divide(R.__, 100),
    R.ifElse(
      R.both(propEqLegacy('type', ProjectType.WORK_ORDER), getTmMarkupRules),
      R.compose(
        getMarkupPercentBase(convertDollarsToCents(nte)),
        getTmMarkupRules,
      ),
      R.always(0),
    ),
  )(project);

// isNTEExceedAdminPOBalance :: {value: Number  project: Project} -> Boolean
export const isNTEExceedAdminPOBalance = R.ifElse(
  R.both(
    R.prop('nte'),
    R.path(['project', 'adminPurchaseOrder', 'currentBalance']),
  ),
  R.converge(R.gte, [
    getNTEAmountWithMarkup,
    R.path(['project', 'adminPurchaseOrder', 'currentBalance']),
  ]),
  R.F,
);

export function SupplierNTEInput({ value, formData: { project }, ...props }) {
  const isExceed = isNTEExceedAdminPOBalance({ project, nte: value });

  const warning = isExceed
    ? 'The NTE amount exceeds the balance of the PO.'
    : '';

  return (
    <NTEInputWrapper>
      <SupplierNTEInputS
        value={value}
        {...props}
        onKeyDown={onKeyDownToPreventFormSubmit}
      />
      {warning && <InputWarningMsg>{warning}</InputWarningMsg>}
    </NTEInputWrapper>
  );
}

SupplierNTEInput.propTypes = {
  value: oneOfType([string, number]),
  formData: shape({}),
};

function NextTaskDueDate(props) {
  return (
    <NextTaskDueDateField width="100%" position="top" labelDark {...props} />
  );
}

// isRenderSupplierInstructions :: FormData -> Boolean
const isRenderSupplierInstructions = R.either(
  R.prop(SupplierWOCTypes.confirm),
  R.prop(SupplierWOCTypes.request),
);

// validateSuppliers :: Boolean -> FormData -> ErrorObject
const validateSuppliers = (noSupplierRequired) =>
  R.compose(
    R.ifElse(
      R.anyPass([
        () => noSupplierRequired,
        R.anyPass([
          isSelectedSuppliersType(supplierTypes.SUBCONTRACTOR),
          isSelectedSuppliersType(supplierTypes.ADMINISTRATIVE),
        ]),
      ]),
      R.always({}),
      R.always({ errors: { suppliers: 'No selected suppliers' } }),
    ),
    R.prop('suppliers'),
  );

const CheckBoxesWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 15px;
  }
`;

const TextAreaContainer = styled(SectionWrapper)`
  flex-direction: column;
  padding: 0 24px;

  > div:first-child {
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 6px;
  }
`;

const FormFooter = styled(SectionWrapper)`
  justify-content: flex-start;

  > button:first-child {
    margin-right: 15px;
  }
`;

const FormS = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const FilePikerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 24px;
  gap: 5px;
  > div:first-child {
    font-size: 11px;
  }
`;

const CheckboxS = styled(Checkbox)`
  margin-top: 7px;
  margin-bottom: 7px;
`;

// getSubmitButtonText:: String -> String
const getSubmitButtonText = R.cond([
  [R.prop('noSupplierRequired'), R.always('Complete Task')],
  [
    R.propSatisfies(isAssignSupplierFormType, 'formType'),
    R.always('Assign Supplier'),
  ],
  [R.propSatisfies(isSendWOCFormType, 'formType'), R.always('Send WOC')],
  [
    R.propSatisfies(isGenerateWOCFormType, 'formType'),
    R.always('Generate WOC'),
  ],
  [R.T, R.always('Add Supplier')],
]);

const getSelectedSupplier = R.compose(
  R.head,
  pickSelectedSuppliers,
  R.prop('suppliers'),
);

// isAssignOrWOCFormType :: String -> Boolean
const isAssignOrWOCFormType = R.anyPass([
  isAssignSupplierFormType,
  isSendWOCFormType,
  isGenerateWOCFormType,
]);

// isAssignSupplierOrSendWOCFormType :: FromData -> Boolean
const isAssignSupplierOrSendWOCFormType = R.either(
  isAssignSupplierFormType,
  isSendWOCFormType,
);

// isWithWOCEmailAttachments :: {formType: String, confirmWorkOrder: Boolean, noSupplierRequired: Boolean}
// -> Boolean
// Input = {
//    formType: String
//    confirmWorkOrder: Boolean
//    requestFreeQuote: Boolean
//    noSupplierRequired: Boolean
// }
const isWithWOCEmailAttachments = R.both(
  R.complement(R.prop('noSupplierRequired')),
  R.anyPass([
    R.both(
      R.prop('confirmWorkOrder'),
      R.propSatisfies(isAssignSupplierFormType, 'formType'),
    ),
    R.both(
      R.prop('confirmWorkOrder'),
      R.propSatisfies(isSendWOCFormType, 'formType'),
    ),
    R.both(
      R.complement(R.prop('requestFreeQuote')),
      R.propSatisfies(isGenerateWOCFormType, 'formType'),
    ),
  ]),
);

// checkIsSendWocDisabled :: { formType: String, confirmWorkOrder: Boolean, requestFreeQuote: Boolean } -> Boolean
const checkIsSendWocDisabled = R.allPass([
  R.propSatisfies(isSendWOCFormType, 'formType'),
  R.complement(R.path(['values', SupplierWOCTypes.request])),
  R.complement(R.path(['values', SupplierWOCTypes.confirm])),
]);

const MultiEmailsInputWrapper = styled.div`
  width: 100%;
`;

export const SUPPLIER_EMAILS_QUERY = gql`
  query SUPPLIER_EMAILS_QUERY($supplierId: ID!) {
    supplier(id: $supplierId) {
      _id
      company {
        emails {
          email
          type
        }
      }
    }
  }
`;

// getSupplierSecondaryServiceEmailsOptions :: SupplierQueryResult -> [Option]
const getSupplierSecondaryServiceEmailsOptions = R.compose(
  R.map(R.applySpec({ value: R.prop('email'), label: R.prop('email') })),
  R.filter(propEqLegacy('type', supplierEmailsTypes.SECONDARY_SERVICE)),
  R.pathOr([], ['supplier', 'company', 'emails']),
);

function MultiWOCEmailsInput({ label, hideLabel, ...props }) {
  return (
    <MultiEmailsInputWrapper>
      {!hideLabel && <LabelS color="midDark">Send the WOC to</LabelS>}
      <NotificationContacts {...props} />
    </MultiEmailsInputWrapper>
  );
}

MultiWOCEmailsInput.propTypes = {
  hideLabel: bool,
  label: string,
};

export function MultiSupplierWOCEmailsInput({
  onChange,
  formData,
  skipAutofill,
  ...props
}) {
  const selectedSupplierId = getSelectedSupplier(formData);

  const { data } = useQuery(SUPPLIER_EMAILS_QUERY, {
    variables: { supplierId: selectedSupplierId },
    skip: skipAutofill || !selectedSupplierId,
  });

  useEffect(() => {
    if (!skipAutofill && selectedSupplierId) {
      onChange(getSupplierSecondaryServiceEmailsOptions(data));
    }
  }, [data]);

  return <MultiWOCEmailsInput onChange={onChange} {...props} />;
}

MultiSupplierWOCEmailsInput.propTypes = {
  onChange: func.isRequired,
  formData: shape({}),
  skipAutofill: bool,
};

function SendClientConfirmationEmailFormFields() {
  return (
    <>
      <SectionWrapper>
        <FormField
          name="clientWorkOrderConfirmation.additionalEmails"
          Component={MultiWOCEmailsInput}
          additionalProps={{ label: 'Send Client WOC to' }}
        />
      </SectionWrapper>
      <TextAreaContainer>
        <Text>Additional Instructions</Text>
        <FormField
          name="clientWorkOrderConfirmation.instruction"
          Component={TextAreaS}
          additionalProps={{
            placeholder: 'Enter additional instructions',
            rows: 4,
          }}
        />
      </TextAreaContainer>
      <FilePikerWrapper>
        <Text>Attach Files</Text>
        <FormField
          name="clientWorkOrderConfirmation.attachments"
          Component={FilePicker}
          validate={validateFilesFunc(commonFileValidators)}
        />
      </FilePikerWrapper>
    </>
  );
}

function AssignSupplierForm({
  form,
  values,
  disabled,
  handleSubmit,
  noSupplierRequired,
  setNoSupplierRequired,
  formType,
  supplierId,
  defaultWOInstructions,
}) {
  const [isFormReset, setIsFormReset] = useState(false);

  const resetForm = (e) => {
    e.preventDefault();
    form.setConfig('keepDirtyOnReinitialize', false);
    form.reset();
    form.setConfig('keepDirtyOnReinitialize', true);
    setIsFormReset(!isFormReset);
  };
  const { formSubscription } = usePristineSubscribe();
  useFormSubscription(form.subscribe, formSubscription);

  const isSupplierSelected = getSelectedSupplier(values);

  const isDisable = !noSupplierRequired && !isSupplierSelected && !supplierId;
  const isSendWocDisabled = checkIsSendWocDisabled({ formType, values });

  const isWithSupplierEmail =
    (values[SupplierWOCTypes.confirm] || values[SupplierWOCTypes.request]) &&
    !noSupplierRequired;

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  return (
    <FormS
      onSubmit={handleSubmit}
      id={assignSupplierFormId}
      onKeyDown={onKeyDown}
    >
      <AssignSupplierFormWithSearch
        formData={values}
        formType={formType}
        supplierId={supplierId}
        isFormReset={isFormReset}
        noSupplierRequired={noSupplierRequired}
        setNoSupplierRequired={setNoSupplierRequired}
      />

      {values.isComplete && !noSupplierRequired && (
        <SectionWrapper padding="24px">
          <InputWrapper flex={1} margin="0 10px 0 0">
            <Text>Assigned to</Text>
            <FormField name="managerId" Component={UserSelect} />
          </InputWrapper>
          <InputWrapper flex={1}>
            <FormField name="nextTask" Component={NextTaskDueDate} />
          </InputWrapper>
        </SectionWrapper>
      )}

      {!noSupplierRequired && isAssignOrWOCFormType(formType) && (
        <MainSectionWrapper>
          <CheckBoxesWrapper>
            {!isGenerateWOCFormType(formType) && (
              <FormField
                name={SupplierWOCTypes.confirm}
                Component={CheckWOComponent}
                additionalProps={{
                  label: 'Send Supplier WOC',
                  formData: values,
                  changeFieldValue: form.change,
                  relatedText: defaultWOInstructions,
                  relatedCheckbox: SupplierWOCTypes.request,
                }}
              />
            )}
            <FormField
              name={SupplierWOCTypes.request}
              Component={CheckWOComponent}
              additionalProps={{
                label: 'Free Quote',
                formData: values,
                changeFieldValue: form.change,
                relatedText: requestFreeQuoteNote,
                relatedCheckbox: isGenerateWOCFormType(formType)
                  ? null
                  : SupplierWOCTypes.confirm,
              }}
            />
          </CheckBoxesWrapper>
          <FormField
            name="nte"
            Component={SupplierNTEInput}
            additionalProps={{
              formData: values,
              width: '212px',
              placeholder: '$0.00',
              label: 'Supplier NTE',
              labelPosition: 'left',
            }}
          />
        </MainSectionWrapper>
      )}
      {isWithSupplierEmail && isAssignSupplierOrSendWOCFormType(formType) && (
        <SectionWrapper>
          <FormField
            name="additionalSupplierWOCEmails"
            Component={MultiSupplierWOCEmailsInput}
            additionalProps={{
              supplierId,
              formData: values,
              label: 'Send the WOC to',
            }}
          />
        </SectionWrapper>
      )}
      {isRenderSupplierInstructions(values) &&
        !noSupplierRequired &&
        isAssignOrWOCFormType(formType) && (
          <TextAreaContainer>
            <Text>Supplier Instructions</Text>
            <FormField
              name="supplierNote"
              Component={TextAreaS}
              additionalProps={{
                formData: values,
                placeholder: 'Enter supplier instructions',
                rows: 4,
              }}
            />
          </TextAreaContainer>
        )}
      {isWithWOCEmailAttachments({
        ...values,
        formType,
        noSupplierRequired,
      }) && (
        <FilePikerWrapper>
          <Text>Attach Files</Text>
          <FormField
            name="attachments"
            Component={FilePicker}
            validate={validateFilesFunc(commonFileValidators)}
          />
        </FilePikerWrapper>
      )}
      {!noSupplierRequired && isAssignSupplierFormType(formType) && (
        <MainSectionWrapper>
          <CheckBoxesWrapper>
            {!isGenerateWOCFormType(formType) && (
              <FormField
                name="sendClientWOCEmail"
                Component={CheckboxS}
                additionalProps={{
                  label: 'Send Client WOC',
                }}
              />
            )}
          </CheckBoxesWrapper>
        </MainSectionWrapper>
      )}
      {values.sendClientWOCEmail && <SendClientConfirmationEmailFormFields />}
      <FormFooter>
        <Button
          size="tiny"
          type="submit"
          loader={disabled}
          disabled={disabled || isDisable || isSendWocDisabled}
          styleType="accentDark"
          data-testid="assignSupplierSubmitButton"
          form={assignSupplierFormId}
        >
          {getSubmitButtonText({ noSupplierRequired, formType })}
        </Button>
        <Button size="tiny" onClick={resetForm} styleType="basicSecondary">
          Reset
        </Button>
      </FormFooter>
    </FormS>
  );
}

AssignSupplierForm.propTypes = {
  handleSubmit: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: object,
  // eslint-disable-next-line react/forbid-prop-types
  form: object,
  defaultWOInstructions: string,
  disabled: bool,
  noSupplierRequired: bool.isRequired,
  setNoSupplierRequired: func.isRequired,
  formType: assignSupplierFormTypePropTypes.isRequired,
  supplierId: string,
};

// getProjectIdFormInitialValues :: { entity: { _id: ID, name: String } } -> { recurringProjectId: ID, projectId: ID }
const getProjectIdFormInitialValues = R.cond([
  [
    pathEqLegacy(['entity', 'name'], entities.PROJECT),
    R.applySpec({ projectId: R.path(['entity', '_id']) }),
  ],
  [
    pathEqLegacy(['entity', 'name'], entities.RECURRING_PROJECT),
    R.applySpec({ recurringProjectId: R.path(['entity', '_id']) }),
  ],
  [R.T, R.always({})],
]);

// eslint-disable-next-line import/no-unused-modules
export const PROJECT_PROPERTY_QUERY = gql`
  query PROJECT_PROPERTY_QUERY($id: ID) {
    project(id: $id) {
      _id
      type
      adminPurchaseOrder {
        _id
        currentBalance
      }
      property {
        _id
        defaultWOInstructions
        tmMarkupRules {
          upTo
          percent
        }
      }
      client {
        _id
        tmMarkupRules {
          upTo
          percent
        }
      }
    }
  }
`;

const RECURRING_PROJECT_PROPERTY_QUERY = gql`
  query RECURRING_PROJECT_PROPERTY_QUERY($id: ID) {
    recurringProject(id: $id) {
      _id
      type
      adminPurchaseOrder {
        _id
        currentBalance
      }
      property {
        _id
        defaultWOInstructions
        tmMarkupRules {
          upTo
          percent
        }
      }
      client {
        _id
        tmMarkupRules {
          upTo
          percent
        }
      }
    }
  }
`;

// getDefaultWOInstructions :: { project: Project } -> JSON
const getDefaultWOInstructions = R.either(
  R.path(['projectData', 'project', 'property', 'defaultWOInstructions']),
  R.pathOr('', [
    'recurringProjectData',
    'recurringProject',
    'property',
    'defaultWOInstructions',
  ]),
);

const getProjectByQueryData = R.either(
  R.path(['projectData', 'project']),
  R.path(['recurringProjectData', 'recurringProject']),
);

export function AssignSupplierFormComp({
  onSubmit: submitHandler,
  initialValues,
  disabled,
  noSupplierRequired,
  setNoSupplierRequired,
  formType,
  supplierId,
}) {
  const { projectId, recurringProjectId } =
    getProjectIdFormInitialValues(initialValues);

  const { data: projectData } = useQuery(PROJECT_PROPERTY_QUERY, {
    variables: { id: projectId },
    fetchPolicy: 'network-only',
    skip: !projectId,
  });

  const { data: recurringProjectData } = useQuery(
    RECURRING_PROJECT_PROPERTY_QUERY,
    {
      variables: { id: recurringProjectId },
      fetchPolicy: 'network-only',
      skip: !recurringProjectId,
    },
  );

  const { data: supplierData, loading } = useQuery(SUPPLIER_EMAILS_QUERY, {
    variables: { supplierId },
    skip: !supplierId,
  });

  const defaultWOInstructions = getDefaultWOInstructions({
    projectData,
    recurringProjectData,
  });

  const project = getProjectByQueryData({ projectData, recurringProjectData });

  const clearPristine = useClearPristineState();

  useEffect(() => clearPristine, []);

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    assignSupplierFormId,
    submitHandler,
  );

  if (loading) {
    return null;
  }

  return (
    <Form
      render={AssignSupplierForm}
      onSubmit={onSubmit}
      initialValues={{
        project,
        ...initialValues,
        ...(defaultWOInstructions
          ? { supplierNote: defaultWOInstructions }
          : {}),
        additionalSupplierWOCEmails:
          getSupplierSecondaryServiceEmailsOptions(supplierData),
        clientWorkOrderConfirmation: { attachments: [] },
      }}
      {...(!supplierId
        ? { validate: validateSuppliers(noSupplierRequired) }
        : {})}
      disabled={disabled}
      noSupplierRequired={noSupplierRequired}
      setNoSupplierRequired={setNoSupplierRequired}
      formType={formType}
      supplierId={supplierId}
      subscription={{ values: true }}
      formId={assignSupplierFormId}
      defaultWOInstructions={defaultWOInstructions}
      keepDirtyOnReinitialize
    />
  );
}

AssignSupplierFormComp.propTypes = {
  onSubmit: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: object,
  disabled: bool,
  noSupplierRequired: bool.isRequired,
  setNoSupplierRequired: func.isRequired,
  formType: assignSupplierFormTypePropTypes.isRequired,
  supplierId: string,
};
