import React, { useState } from 'react';
import styled from 'styled-components';
import { string, bool } from 'prop-types';
import { Loader } from '@poly/admin-book';

import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import { TableCardWithPaginationContainer } from '@poly/admin-ui/src/components/Table.js';

import { BankRegistersBody } from './BankRegistersBody.js';
import { BankRegistersHeader } from './BankRegistersHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { useAccountRegistersQuery } from './useAccountRegistersQuery.js';

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  height: calc(100% - 134px);
`;

export function AccountRegistersPage({
  title,
  exportTitle,
  hideCheckNumber,
  systemAccountType,
}) {
  const [query, setQuery] = useState(null);

  const { data, loading, tableProps } = useAccountRegistersQuery(
    query,
    hideCheckNumber,
  );

  return (
    <PageWithSearchHeader headerHeight="0px">
      <BankRegistersHeader
        data={data}
        query={query}
        title={title}
        loading={loading}
        setQuery={setQuery}
        exportTitle={exportTitle}
        hideCheckNumber={hideCheckNumber}
        systemAccountType={systemAccountType}
      />
      {loading ? (
        <Loader />
      ) : (
        <DefaultBodyWrapperS>
          <TableCardWithPaginationContainer>
            <BankRegistersBody
              data={data}
              query={query}
              tableProps={tableProps}
              hideCheckNumber={hideCheckNumber}
            />
          </TableCardWithPaginationContainer>
        </DefaultBodyWrapperS>
      )}
    </PageWithSearchHeader>
  );
}

AccountRegistersPage.propTypes = {
  hideCheckNumber: bool,
  title: string.isRequired,
  systemAccountType: string,
  exportTitle: string.isRequired,
};
